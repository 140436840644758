import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Buchheim: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Buchheim,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Buchheim"
          d="M720,490l27.5,90.2c-15.8,13.4-34.9,18.5-52.8,19.5L694,598l-10-30l22-50l-10-12L720,490z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 699.2911 563.4146)"
        >
          <tspan x="0" y="0">
            Buch-
          </tspan>
          <tspan x="0" y="14.4">
            heim
          </tspan>
        </text>
      </g>
    </>
  );
});
